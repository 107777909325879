<template>
  <a class="delete-button"
     :href="href"
     :disabled="disabled">
    <i :class="`${icon}`"
       v-if="icon!=''"></i>
  </a>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: 'javascript:;',
    },
  },
}
</script> 

<style lang="scss" scoped>
.delete-button {
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }

  & > i {
    font-style: normal;
    border-radius: 6px;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    padding: 8px;
    color: #f64e60;
    background-color: #f3f6f9;
    border-color: transparent;
    transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  & > i:hover {
    color: #f3f6f9;
    background-color: #f64e60;
    border-color: transparent;
    text-decoration: none;
  }
}
</style>